import React from 'react';

import Layout from "../layouts/site/layout";
import Products from '../images/Products/msi-projects.jpg';

function Values() {
  return (
    <div className="App">
       <Layout>
           <div className="bg-white">
                <img src={Products} className="App-logo pt-2 pl-2" alt="logo" />
           </div>
       </Layout>
    </div>
  );
}

export default Values;
